import React from 'react';
import { Nav, NavItem, NavLink, Link } from 'reactstrap';
import PropTypes from 'prop-types';

import {
  AppAsideToggler,
  AppNavbarBrand,
  AppSidebarToggler
} from '@coreui/react';
import logo from 'src/assets/img/logo.png';
import DefaultHeaderDropdown from './DefaultHeaderDropdown';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

const DefaultHeader = ({ children, ...attributes }) => {
  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <a
        href="/"
        alt="Home"
        className="navbar-brand"
        style={{
          background: `url("${logo}") 50% 44% / 120px no-repeat`
        }}
      />
      {/* <AppNavbarBrand full={{ src: logo, height: 25, alt: 'CoreUI Logo' }} /> */}
      <AppSidebarToggler className="d-md-down-none" display="lg" />
      <Nav className="d-md-down-none" navbar>
        <NavItem className="px-3">
          <NavLink href="#">Мои кампании</NavLink>
        </NavItem>
        <NavItem className="px-3">
          <NavLink href="/dashboard/create-company">Создать кампанию</NavLink>
        </NavItem>
        <NavItem className="px-3">
          <NavLink href="#">Инструменты</NavLink>
        </NavItem>
        <NavItem className="px-3">
          <NavLink href="#">Рекомендации</NavLink>
        </NavItem>
      </Nav>
      <Nav className="ml-auto" navbar>
        <DefaultHeaderDropdown notif />
        <DefaultHeaderDropdown tasks />
        <DefaultHeaderDropdown mssgs />
        <NavItem className="d-md-down-none">
          <NavLink href="#">
            <i className="icon-location-pin" />
          </NavLink>
        </NavItem>
        <DefaultHeaderDropdown accnt />
      </Nav>
      {/* <AppAsideToggler className="d-md-down-none" /> */}
    </React.Fragment>
  );
};

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
