import {
  SET_REGIONS,
  CLEAR_REGIONS,
  SET_SELECTED_REGIONS,
  SET_EXPANDED_LIST,
  SET_REGION_LIST_FROM_BUDGET,
  CLEAR_REGION_LIST_FROM_BUDGET
} from './actionTypes';

export const initialState = {
  regions: [],
  selectedRegions: [],
  fullList: [],
  expandedList: [],
  regionsFromBudget: []
};

const GeoRegionsReducer = (state = initialState, action) => {
  const {
    type,
    regions,
    selectedRegions,
    fullList,
    expandedList,
    regionsFromBudget
  } = action;
  switch (type) {
    case SET_REGIONS:
      return { ...state, regions, fullList };
    case SET_SELECTED_REGIONS:
      return { ...state, selectedRegions };
    case SET_EXPANDED_LIST:
      return { ...state, expandedList };
    case SET_REGION_LIST_FROM_BUDGET:
      return { ...state, regionsFromBudget };
    case CLEAR_REGION_LIST_FROM_BUDGET:
      return { ...state, regionsFromBudget: [] };

    case CLEAR_REGIONS:
      return initialState;
    default:
      return state;
  }
};

export default GeoRegionsReducer;
