import { uniqBy } from 'lodash';

export function minimize() {
  const body = document.body;
  const nav = document.getElementsByClassName('sidebar-nav')[0];
  body.classList.add('sidebar-minimized', 'brand-minimized');
  nav.classList.remove('ps-container', 'ps', 'ps--active-y');
}
export function maximize() {
  const body = document.body;
  const nav = document.getElementsByClassName('sidebar-nav')[0];
  body.classList.remove('sidebar-minimized', 'brand-minimized');
  nav.classList.add('ps-container', 'ps', 'ps--active-y');
}

export const checkPath = pathname => {
  const subMenu = pathname.split('/').length > 2;
  const nav = document.getElementsByClassName('sidebar-nav')[0];
  const body = document.body;
  const isMinimize = body.classList.contains('sidebar-minimized');
  if (subMenu && !isMinimize) minimize();
  if (!subMenu && isMinimize) maximize();
  if (subMenu) {
    nav.addEventListener('mouseenter', maximize);
    nav.addEventListener('mouseleave', minimize);
  } else {
    nav.removeEventListener('mouseenter', maximize);
    nav.removeEventListener('mouseleave', minimize);
  }
};

export const thousandSeporator = (number, needCeil = true) => {
  const digit = String(needCeil ? Math.ceil(number) : number);
  const reg = /(\d)(?=(\d\d\d)+([^\d]|$))/g;
  if (needCeil || (number ^ 0) === number) {
    return digit.replace(reg, '$1 ');
  } else {
    const tail = digit.substr(-(digit.length - digit.indexOf('.')));
    return (
      digit.substr(0, digit.length - tail.length).replace(reg, '$1 ') + tail
    );
  }
};

export const validateKeywords = keywords => {
  const refactorPhrase = item => {
    let proxyItem = item
      .replace(/[^-0-9a-zA-Z\.а-яёЁА-Я\s-]/gim,'')
      .trim()
      .replace(/\r?\t/gim," ")
      .replace(/ {1,}/g," ");
    if(proxyItem[0] === '-' || proxyItem[0] === '—') proxyItem = proxyItem.slice(-(proxyItem.length - 1));
    const sliceStart = proxyItem.indexOf(' -');
    return (sliceStart + 1) ? proxyItem.slice(0, sliceStart) : proxyItem;
  };

  const validatedKeywords = uniqBy(keywords.map(refactorPhrase).filter(Boolean), item => item.toLowerCase());
  let isValid = false;
  const trimWordsNumeration = /([0-9]+\.\))|([0-9]+\))|([0-9]+\.)/gim;
  validatedKeywords.forEach(item => {
    if(!Boolean(item.match(trimWordsNumeration))) isValid = true;
    const symbolSpace = '&nbps;';
    const nbspIndex = item.indexOf(symbolSpace);
    const symbolSpaceRegexp = new RegExp(symbolSpace, 'gim');
    if(nbspIndex + 1){
      if(item[nbspIndex - 1] === ' ' || item[nbspIndex + 1] === ' '){
        item.replace(symbolSpaceRegexp,' ')
      } else {
        item.replace(symbolSpaceRegexp,'')
      }
    }
  });
  const fitKeywords = [];
  const unfitKeywords = [];
  validatedKeywords.forEach(item => {
    let newItem = item[0].toUpperCase() + item.slice(1);
    if(!isValid) newItem = newItem.replace(trimWordsNumeration, '').trim();
    if(item.split(' ').length > 7){
      unfitKeywords.push(newItem);
    } else {
      fitKeywords.push(newItem);
    }
  });

  return {
    fitKeywords,
    unfitKeywords,
  }
};
