import React from 'react';
import { Table, Card, CardHeader, CardBody } from 'reactstrap';
import { RenderRows } from './components/renderRows';
import Pagination from './components/Pagination';

import SearchPanel from './components/SearchPanel';
import UsersSettings from './components/servicesSettings';

const UsersPage = () => (
  <div className="animated fadeIn">
    <Card>
      <CardHeader className="budget-forecast__header">
        <h3 className="budget-forecast__title">Таблица пользователей</h3>
      </CardHeader>
      <CardBody>
        <SearchPanel query="" setPaginationQueryParam="" />
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>E-mail</th>
              <th>Дата последнего сеанса</th>
              <th>Доступ к рассылкам</th>
              <th>Доступ к продвижению</th>
            </tr>
          </thead>
          <tbody>
            <RenderRows />
          </tbody>
        </Table>
        <Pagination page={5} pages={10} setPaginationPage={4} />
      </CardBody>
    </Card>
    <UsersSettings />
  </div>
);

export default UsersPage;
