import React from 'react';
import PropTypes from 'prop-types';
import './preloader.css';

const Preloader = ({ isLoading, position }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div
      className={`loadingio-spinner-eclipse-s3lf6vodbrj ${
        position === 'fixed' ? 'fixed' : null
      }`}
      style={{ position: position }}
    >
      <div className="ldio-656bvh1i9db">
        <div />
      </div>
    </div>
  );
};

Preloader.propTypes = {
  isLoading: PropTypes.bool,
  position: PropTypes.string
};

Preloader.defaultProps = {
  isLoading: false,
  position: ''
};

export default Preloader;
