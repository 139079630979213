const menuWithCustomIcon = ({ budgetTopThreeIcon, budgetTopThreeName }) => ({
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
        text: ''
      }
    },
    {
      title: true,
      name: 'Маркетинг и рассылки'
    },
    {
      name: 'Рассылки',
      url: '#',
      icon: 'icon-layers',
      variant: 'success',
      children: [
        {
          name: 'SMS рассылки',
          url: '#'
        },
        {
          name: 'Viber рассылки',
          url: '#'
        },
        {
          name: 'E-mail рассылки',
          url: '#'
        },
        {
          name: 'Голосовые рассылки',
          url: '#'
        },
        {
          name: 'Таргет рассылки',
          url: '#'
        }
      ]
    },
    {
      name: 'Продвижение',
      url: '#',
      icon: 'icon-layers',
      children: [
        {
          name: budgetTopThreeName ? budgetTopThreeName : 'ТОП-3 в поиске',
          url: '/dashboard/top-three',
          icon: budgetTopThreeIcon ? budgetTopThreeIcon : null
        },
        {
          name: 'Instagram реклама',
          url: '/dashboard/top-three'
        },
        {
          name: 'Таргет реклама',
          url: '/dashboard/top-three'
        },
        {
          name: 'Реклама в соцсетях',
          url: '/dashboard/top-three'
        }
      ]
    },
    {
      name: 'Инструменты',
      url: '#',
      icon: 'icon-layers'
    },
    {
      title: true,
      name: 'Общие разделы'
    },
    {
      name: 'Профиль',
      url: '#',
      icon: 'icon-layers'
    },
    {
      name: 'Support',
      url: '#',
      icon: 'icon-layers'
    },
    {
      name: 'Настройки',
      url: '#',
      icon: 'icon-layers'
    },
    {
      name: 'Управление системой',
      url: '/dashboard/users',
      icon: 'icon-layers'
    }
  ]
});

export { menuWithCustomIcon };

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
        text: ''
      }
    },
    {
      title: true,
      name: 'Маркетинг и рассылки'
    },
    {
      name: 'Рассылки',
      url: '#',
      icon: 'icon-layers',
      variant: 'success',
      children: [
        {
          name: 'SMS рассылки',
          url: '#'
        },
        {
          name: 'Viber рассылки',
          url: '#'
        },
        {
          name: 'E-mail рассылки',
          url: '#'
        },
        {
          name: 'Голосовые рассылки',
          url: '#'
        },
        {
          name: 'Таргет рассылки',
          url: '#'
        }
      ]
    },
    {
      name: 'Продвижение',
      url: '#',
      icon: 'icon-layers',
      children: [
        {
          name: 'ТОП-3 в поиске',
          url: '/dashboard/top-three'
        },
        {
          name: 'Instagram реклама',
          url: '/dashboard/top-three'
        },
        {
          name: 'Таргет реклама',
          url: '/dashboard/top-three'
        },
        {
          name: 'Реклама в соцсетях',
          url: '/dashboard/top-three'
        }
      ]
    },
    {
      name: 'Инструменты',
      url: '#',
      icon: 'icon-layers'
    },
    {
      title: true,
      name: 'Общие разделы'
    },
    {
      name: 'Профиль',
      url: '#',
      icon: 'icon-layers'
    },
    {
      name: 'Support',
      url: '#',
      icon: 'icon-layers'
    },
    {
      name: 'Настройки',
      url: '#',
      icon: 'icon-layers'
    },
    {
      name: 'Управление системой',
      url: '/dashboard/users',
      icon: 'icon-layers'
    }
  ]
};
