import React, { Component } from 'react';

import { Field } from 'formik';

export default class AdditionalEmail extends Component {
  state = {
    title: '',
    label: ''
  };

  onAdded = () => {
    this.props.addEmail(this.state);
  };

  onTitleChange = e => {
    this.setState({ title: e.target.value });
  };

  onLabelChange = e => {
    this.setState({ label: e.target.value });
  };

  render() {
    const { handleBlur, cancelEmail } = this.props;

    return (
      <div className="additional col-12">
        <div className="form-group col-12">
          <label htmlFor="additionalEmailTitle">
            <span>Заголовок</span>
          </label>
          <Field
            type="text"
            onChange={this.onTitleChange}
            value={this.state.title}
            name="additionalEmailTitle"
            id="additionalEmailTitle"
            placeholder="Рабочий email"
            onBlur={handleBlur}
            className="form-control"
          />
        </div>
        <div className="form-group col-12">
          <label htmlFor="additionalEmail">
            <span>Дополнительный email</span>
          </label>
          <Field
            type="text"
            onChange={this.onLabelChange}
            value={this.state.label}
            name="additionalEmail"
            id="additionalEmail"
            placeholder="email@email.ru"
            onBlur={handleBlur}
            className="form-control"
          />
        </div>
        <div className="form-group col-12">
          <button
            className="btn btn-success add-btn"
            type="button"
            onClick={this.onAdded}
          >
            Добавить email
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => cancelEmail()}
          >
            Отмена
          </button>
        </div>
      </div>
    );
  }
}
