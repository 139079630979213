import { generateActionTypes } from 'src/helpers/reduxRequestHelper';

const actionTypesRubrics = generateActionTypes('rubrics');
const actionTypesKeywords = generateActionTypes('keywords');

const actionTypesDictionary = generateActionTypes('dictionary');
const actionTypesCategories = generateActionTypes('categories');
const actionTypesBudgetForecast = generateActionTypes('forecast');
const actionTypesBudgetForecastById = generateActionTypes('forecast_by_id');

const paginationActionTypes = {
  SET_PAGINATION_PAGE: 'SET_PAGINATION_PAGE',
  SET_PAGINATION_PAGES: 'SET_PAGINATION_PAGES',
  SET_PER_PAGE: 'SET_PER_PAGE',
  SET_PAGE_SORT_ORDER: 'SET_PAGE_SORT_ORDER',
  SET_PAGE_SORT_FIELD: 'SET_PAGE_SORT_FIELD',
  RESET_PAGINATION_SETTINGS: 'RESET_PAGINATION_SETTINGS',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS'
};

export {
  actionTypesRubrics,
  actionTypesKeywords,
  actionTypesDictionary,
  actionTypesCategories,
  actionTypesBudgetForecast,
  actionTypesBudgetForecastById,
  paginationActionTypes
};
