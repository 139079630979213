import Loadable from 'react-loadable';
import Loading from 'src/components/Layouts/Loading';

const TopThree = Loadable({
  loader: () => import('src/pages/TopThree/Main'),
  loading: Loading
});

const AddClient = Loadable({
  loader: () => import('src/pages/AddClient'),
  loading: Loading
});
const BudgetListForecast = Loadable({
  loader: () => import('src/pages/TopThree/Budget/BudgetListForecast'),
  loading: Loading
});
const NewBudgetForecast = Loadable({
  loader: () => import('src/pages/TopThree/Budget/NewBudgetForecast'),
  loading: Loading
});
const CalculationResult = Loadable({
  loader: () => import('src/pages/TopThree/Budget/BudgetCalculationResult'),
  loading: Loading
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/dashboard/top-three',
    name: 'Top three',
    component: TopThree,
    exact: true
  },
  {
    path: '/dashboard/top-three/add-client',
    name: 'Add Client',
    component: AddClient
  },
  {
    path: '/dashboard/top-three/budget-forecast',
    name: 'Budget Forecast',
    component: BudgetListForecast,
    exact: true
  },
  {
    path: '/dashboard/top-three/budget-forecast/new',
    name: 'New Budget Forecast',
    component: NewBudgetForecast
  },
  {
    path: '/dashboard/top-three/budget-forecast/:id',
    name: 'Calculation Result',
    component: CalculationResult
  }
];

export default routes;
