import DefaultLayout from './Layouts/DefaultLayout';
import ExtraMenu from './Wapp/ExtraMenu';
import Modals from './Wapp/WappModal';
import FormInput from './Wapp/Forms/FormInput';
import Loading from './Layouts/Loading';
import Preloader from './Preloader';
import IconsModal from './IconsModal';

export {
  DefaultLayout,
  ExtraMenu,
  Modals,
  FormInput,
  Loading,
  Preloader,
  IconsModal
};
