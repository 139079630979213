import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function Modals({
  colorModalBtn,
  modalBtn,
  modalClassName,
  modalHeader,
  modalBody,
  modalFooter
}) {
  const [modal, toggle] = useState();
  return (
    <React.Fragment>
      <Button onClick={() => toggle(!modal)} color={colorModalBtn}>
        {modalBtn}
      </Button>
      <Modal
        isOpen={modal}
        toggle={() => toggle(!modal)}
        className={modalClassName}
      >
        <ModalHeader toggle={() => toggle(!modal)}>{modalHeader()}</ModalHeader>
        <ModalBody>{modalBody()}</ModalBody>
        <ModalFooter>{modalFooter()}</ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default Modals;
