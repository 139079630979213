import axios from 'axios';
import { removeToken } from './authTokenHelper';

export function setAuthToken(token) {
  const requestToken = 'Bearer ' + token;
  axios.defaults.headers.common['Authorization'] = requestToken;

  localStorage.setItem('token', requestToken);
}

export const authTokenSync = () => {
  return dispatch => {
    const token = getAuthToken();

    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
    }
  };
};

export function getAuthToken() {
  localStorage.getItem('token');
  return localStorage.getItem('token');
}

export function removeAuthToken() {
  delete axios.defaults.headers.common['Authorization'];
  removeToken();
}

export const setAuthRole = roleName => {
  localStorage.setItem('role', roleName);
};

export const getAuthRole = () => {
  return localStorage.getItem('role');
};

export function removeAuthRole() {
  localStorage.removeItem('role');
}

export const isUserAdmin = getAuthRole() === 'admin';
