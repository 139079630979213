import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { FormInput } from 'src/components';
import register from './store/actions';

const nameRegExp = /^[А-Яа-яёЁ]+$/;
const phoneNumberRegExp = /[(|)|\-|\s|+]/g;

const RegisterSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(nameRegExp, 'Введите корректное имя')
    .required('Обязательно к заполнению'),
  last_name: Yup.string()
    .matches(nameRegExp, 'Введите корректную фамилию')
    .required('Обязательно к заполнению'),
  middle_name: Yup.string().matches(nameRegExp, 'Введите корректное отчество'),
  email: Yup.string()
    .email('Введите корректный Email')
    .required('Обязательно к заполнению'),
  phone: Yup.string()
    .length(17, 'Введите номер полностью')
    .required('Обязательно к заполнению'),
  password: Yup.string()
    .min(6, 'Пароль слишком короткий')
    .max(50, 'Пароль слишком длинный')
    .required('Обязательно к заполнению'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
    .required('Обязательно к заполнению')
});

const initialValues = {
  first_name: '',
  last_name: '',
  middle_name: '',
  email: '',
  phone: '',
  password: '',
  passwordConfirmation: ''
};

const Register = props => {
  const { error, isLoading, handleRegister } = props;

  const submitRegistrationParams = values => {
    const newValues = {
      ...values,
      phone: values.phone.replace(phoneNumberRegExp, '')
    };
    handleRegister(newValues);
  };

  const checkAndRenderErrors = () => {
    const errorType = typeof error;

    const renderError = localError => {
      return (
        <div className="alert alert-danger" role="alert">
          {localError}
        </div>
      );
    };

    if (errorType === 'string') {
      return renderError(error);
    }

    let objectKeys = Object.keys(error);

    const phoneErrorMessage = 'Указанный телефон уже используется в системе';
    const emailErrorMessage = 'Указанный e-mail уже используется в системе';

    return objectKeys.map(key => {
      if (key === 'phone') {
        return renderError(phoneErrorMessage);
      } else {
        return renderError(emailErrorMessage);
      }
    });
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Formik
                  validationSchema={RegisterSchema}
                  initialValues={initialValues}
                  onSubmit={submitRegistrationParams}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <h1>Регистрация</h1>
                      <p className="text-muted">Создать новый аккаунт</p>

                      <FormInput
                        fieldKey="first_name"
                        placeholder="Имя"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="text"
                        autoComplete="name"
                        capitalize
                        inputIcon="icon-user"
                      />

                      <FormInput
                        fieldKey="last_name"
                        placeholder="Фамилия"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="text"
                        capitalize
                        inputIcon="icon-user"
                      />

                      <FormInput
                        fieldKey="middle_name"
                        placeholder="Отчество (не обязательно)"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="text"
                        capitalize
                        inputIcon="icon-user"
                      />

                      <FormInput
                        fieldKey="email"
                        placeholder="E-mail"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="text"
                        autoComplete="email"
                        inputIcon="icon-envelope"
                      />

                      <FormInput
                        fieldKey="phone"
                        placeholder="Телефон"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="text"
                        autoComplete="tel"
                        maskedInput
                        inputIcon="icon-phone"
                      />

                      <FormInput
                        fieldKey="password"
                        placeholder="Пароль"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="password"
                        autoComplete="new-password"
                        inputIcon="icon-lock"
                      />

                      <FormInput
                        fieldKey="passwordConfirmation"
                        placeholder="Повторите пароль"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="password"
                        autoComplete="new-password"
                        inputIcon="icon-lock"
                      />

                      {error && (
                        <Row>
                          <Col xs="12">{checkAndRenderErrors()}</Col>
                        </Row>
                      )}

                      <Row>
                        <Col xs="6">
                          <Button
                            type="submit"
                            color="success"
                            block
                            className="px-4"
                            disabled={
                              isLoading ||
                              !!(
                                errors.email ||
                                errors.password ||
                                errors.first_name ||
                                errors.last_name ||
                                errors.phone ||
                                errors.passwordConfirmation
                              )
                            }
                          >
                            Создать аккаунт
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Link to="/login">
                            <Button color="link" className="px-0">
                              Войти
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth } = state,
    { RegisterReducer } = auth,
    { request } = RegisterReducer,
    { isLoading, errorMessage } = request;

  return {
    isLoading,
    error: errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleRegister: data => {
      dispatch(register(data));
    }
  };
};

Register.propTypes = {
  handleRegister: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

Register.defaultProps = {
  handleRegister: () => {},
  isLoading: false,
  error: ''
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
