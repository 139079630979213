import React from 'react';
import { Input } from 'reactstrap';

const items = [
  {
    id: 1,
    email: 'somemail@mail.ru',
    lastVisit: '12.02.2020',
    maillingAccess: true,
    promotionAcces: true
  },
  {
    id: 2,
    email: 'somemail@mail.ru',
    lastVisit: '12.02.2020',
    maillingAccess: true,
    promotionAcces: false
  },
  {
    id: 3,
    email: 'somemail@mail.ru',
    lastVisit: '12.02.2020',
    maillingAccess: true,
    promotionAcces: true
  },
  {
    id: 4,
    email: 'somemail@mail.ru',
    lastVisit: '12.02.2020',
    maillingAccess: false,
    promotionAcces: true
  }
];

export const RenderRows = () => {
  return items.map(item => {
    return (
      <tr key={item.id}>
        <th scope="row">{item.id}</th>
        <td>{item.email}</td>
        <td>{item.lastVisit}</td>
        <td>
          <Input type="select" name="selectMulti" value={item.maillingAccess}>
            <option value={true}>Да</option>
            <option value={false}>Нет</option>
          </Input>
        </td>
        <td>
          {' '}
          <Input type="select" name="selectMulti" value={item.promotionAcces}>
            <option value={true}>Да</option>
            <option value={false}>Нет</option>
          </Input>
        </td>
      </tr>
    );
  });
};
