import { generateActions } from 'src/helpers/reduxRequestHelper';
import { setAuthRole } from 'src/helpers/authToken';
import { getSettings } from 'src/pages/TopThree/Settings/store/actions';
import actionTypes from './actionTypes';

const profileRequest = generateActions(actionTypes, 'user');

const getProfileInfo = () => {
  return (dispatch, getState) => {
    profileRequest.get(dispatch).then(() => {
      const state = getState(),
        { auth } = state,
        { ProfileReducer } = auth,
        { data } = ProfileReducer,
        { user } = data;

      if (user) {
        const { roles } = user;

        const role = roles[0];

        if (role) {
          const { slug } = role;
          setAuthRole(slug);
        }
      }

      dispatch(getSettings());
    });
  };
};

export default { getProfileInfo, request: profileRequest };
