import { generateActionTypes } from 'src/helpers/reduxRequestHelper';

export const SET_DISCOUNT = 'SET_DISCOUNT';
export const CLEAR_BUDGET_DISCOUNT = 'CLEAR_BUDGET_DISCOUNT';
export const SET_SELECTED_ID = 'SET_SELECTED_ID';
export const CLEAR_SELECTED_ID = 'CLEAR_SELECTED_ID';
export const SET_SELECTED_ALL_IDS = 'SET_SELECTED_ALL_IDS';
export const CLEAR_SELECTED_ALL_IDS = 'CLEAR_SELECTED_ALL_IDS';
export const SET_BUDGET_TABLE = 'SET_BUDGET_TABLE';
export const CLEAR_BUDGET_TABLE = 'CLEAR_BUDGET_TABLE';

export const SET_TOTAL_DIRECT_PRICE = 'SET_TOTAL_DIRECT_PRICE';
export const CLEAR_TOTAL_DIRECT_PRICE = 'SET_TOTAL_DIRECT_PRICE';
export const SET_TOTAL_DIRECT_TOP_THREE = 'SET_TOTAL_DIRECT_TOP_THREE';
export const CLEAR_TOTAL_DIRECT_TOP_THREE = 'SET_TOTAL_DIRECT_TOP_THREE';
export const SET_TOTAL_DIRECT_AFTER_THREE_MONTH =
  'SET_TOTAL_DIRECT_AFTER_THREE_MONTH';
export const CLEAR_TOTAL_DIRECT_AFTER_THREE_MONTH =
  'SET_TOTAL_DIRECT_AFTER_THREE_MONTH';

export const SET_TABLE_PRICES = 'SET_TABLE_PRICES';
export const CLEAR_TABLE_PRICES = 'CLEAR_TABLE_PRICES';

export const REMOVE_TABLE_ELEMENT_BY_ID = 'REMOVE_TABLE_ELEMENT_BY_ID';
export const SET_CHANGED_ID = 'SET_CHANGED_ID';
export const SET_CHANGED_ID_WITH_PHRASE = 'SET_CHANGED_ID_WITH_PHRASE';

export const CLEAR_CHANGED_ID = 'CLEAR_CHANGED_ID';
export const CLEAR_CHANGED_ID_WITH_PHRASE = 'CLEAR_CHANGED_ID_WITH_PHRASE';

export const actionTypesBudgetCustomForecastCreate = generateActionTypes(
  'forecast/custom'
);
export const actionTypesBudgetCustomForecastById = generateActionTypes(
  'forecast_by_id/custom'
);

export const actionTypesBudgetSpecialCustomForecastCreate = generateActionTypes(
  'special/forecast/custom'
);
export const actionTypesBudgetSpecialCustomForecastById = generateActionTypes(
  'special/forecast_by_id/custom'
);

export const SET_CUSTOM_FORECAST_ID = 'SET_CUSTOM_FORECAST_ID';
export const CLEAR_CUSTOM_FORECAST_ID = 'CLEAR_CUSTOM_FORECAST_ID';
export const SET_CUSTOM_FORECAST_RECEIVED = 'SET_CUSTOM_FORECAST_RECEIVED';
export const CLEAR_CUSTOM_FORECAST_RECEIVED = 'CLEAR_CUSTOM_FORECAST_RECEIVED';

export const SET_SPECIAL_CUSTOM_FORECAST_ID = 'SET_SPECIAL_CUSTOM_FORECAST_ID';
export const CLEAR_SPECIAL_CUSTOM_FORECAST_ID =
  'CLEAR_SPECIAL_CUSTOM_FORECAST_ID';
export const SET_SPECIAL_CUSTOM_FORECAST_RECEIVED =
  'SET_SPECIAL_CUSTOM_FORECAST_RECEIVED';
export const CLEAR_SPECIAL_CUSTOM_FORECAST_RECEIVED =
  'CLEAR_SPECIAL_CUSTOM_FORECAST_RECEIVED';

export const SET_REMOVED_ROWS = 'SET_REMOVED_ROWS';
export const CLEAR_REMOVED_ROWS = 'CLEAR_REMOVED_ROWS';

export const CLEAR_ALL_CHANGED_IDS = 'CLEAR_ALL_CHANGED_IDS';
