import Loadable from 'react-loadable';
import Loading from 'src/components/Layouts/Loading';
import BudgetRoutes from 'src/pages/TopThree/Budget';

const Dashboard = Loadable({
  loader: () => import('./pages/Dashboard'),
  loading: Loading
});

const CreateCompany = Loadable({
  loader: () => import('./pages/CreateCompany'),
  loading: Loading
});

const Settings = Loadable({
  loader: () => import('./pages/TopThree/Settings'),
  loading: Loading
});

const UsersPage = Loadable({
  loader: () => import('./pages/Users'),
  loading: Loading
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, exact: true },
  ...BudgetRoutes,
  {
    path: '/dashboard/top-three/create-company',
    name: 'Create Company',
    component: CreateCompany,
    exact: true
  },
  {
    path: '/dashboard/top-three/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/dashboard/users',
    name: 'users',
    component: UsersPage
  }
];

export default routes;
