import { generateReducers } from 'src/helpers/reduxRequestHelper';
import settingsActionTypes from './actionTypes';

const handleSettingsData = data => {
  const { settings_ctr_and_clicks, ...otherData } = data;

  if (settings_ctr_and_clicks) {
    const parsedSettings = JSON.parse(settings_ctr_and_clicks);

    return {
      ...otherData,
      settings_ctr_and_clicks: parsedSettings
    };
  }

  return data;
};

const settingsReducer = generateReducers(
  settingsActionTypes,
  handleSettingsData
);

export default settingsReducer;
