import React, { Component } from 'react';

import { Field } from 'formik';

export default class AdditionalPhone extends Component {
  state = {
    title: '',
    label: ''
  };
  render() {
    const { handleBlur, cancelPhone } = this.props;

    const onAdded = () => {
      this.props.addPhone(this.state);
    };

    const onTitleChange = e => {
      this.setState({ title: e.target.value });
    };

    const onLabelChange = e => {
      this.setState({ label: e.target.value });
    };
    return (
      <div className="additional col-12">
        <div className="form-group col-12">
          <label htmlFor="additionalPhoneTitle">
            <span>Заголовок</span>
          </label>
          <Field
            type="text"
            onChange={() => onTitleChange()}
            value={this.state.title}
            name="additionalPhoneTitle"
            id="additionalPhoneTitle"
            placeholder="Рабочий телефон"
            onBlur={handleBlur}
            className="form-control"
          />
        </div>
        <div className="form-group col-12">
          <label htmlFor="additionalPhone">
            <span>Дополнительный email</span>
          </label>
          <Field
            type="text"
            onChange={() => onLabelChange()}
            value={this.state.label}
            name="additionalPhone"
            id="additionalPhone"
            placeholder="+7(900)000-00-00"
            onBlur={handleBlur}
            className="form-control"
          />
        </div>
        <div className="form-group col-12">
          <button
            className="btn btn-success add-btn"
            type="button"
            onClick={onAdded}
          >
            Добавить телефон
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={() => cancelPhone()}
          >
            Отмена
          </button>
        </div>
      </div>
    );
  }
}
