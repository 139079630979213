import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { AppSidebarNav } from '@coreui/react';
import * as router from 'react-router-dom';
import { menuByRole as MainMenu } from 'src/constants/menu';

const ExtraMenu = props => {
  const { page } = props;
  const menuByKey = MainMenu()[page];

  return (
    <>
      {menuByKey ? (
        <div className="animated fadeIn wapp-menu-container">
          <Row>
            <div className="wapp-extra-menu">
              <AppSidebarNav navConfig={menuByKey} {...props} />
            </div>
          </Row>
        </div>
      ) : null}
    </>
  );
};

ExtraMenu.propTypes = {
  page: PropTypes.string.isRequired
};

export default router.withRouter(ExtraMenu);
