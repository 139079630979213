import { setAuthToken } from 'src/helpers/authToken';
import { generateActions } from 'src/helpers/reduxRequestHelper';
import history from 'src/utils/history';
import actionTypes from './actionTypes';
import profileAction from '../../Profile/store/actions';

const registerActions = generateActions(actionTypes, 'register');

export default function register(data) {
  return (dispatch, getState) => {
    registerActions.post(dispatch, data).then(() => {
      const registerState = getState(),
        { auth } = registerState,
        requestState = auth.RegisterReducer.request;

      if (requestState.loaded) {
        if (!requestState.hasError) {
          const user = requestState.data;

          setAuthToken(user['token']);
          history.push('/dashboard');
          dispatch(profileAction.getProfileInfo());
        } else {
          const registerState = getState(),
            { auth } = registerState,
            requestState = auth.RegisterReducer.request,
            { errorMessage } = requestState;

          if (typeof errorMessage === 'string') {
            dispatch(
              registerActions.fail(
                'Не удалось зарегестрироваться, проверьте правильность данных или попробуйте позже'
              )
            );
          } else {
            dispatch(registerActions.fail(errorMessage));
          }
        }
      }
    });
  };
}
