import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup,
  Label
} from 'reactstrap';

const ServicesEditForm = props => {
  const { onChangeField, cleanUp, addService, saveService, formState } = props;
  const { isOpen, description, title, iconPath, isNew, id } = formState;
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        {isNew ? 'Новый сервис' : 'Редактировать сервис'}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="title">Название сервиса</Label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="Название сервиса"
              value={title}
              onChange={e => {
                onChangeField('title', e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Описание</Label>
            <Input
              type="textarea"
              name="text"
              id="description"
              placeholder="Описание"
              value={description}
              onChange={e => {
                onChangeField('description', e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <Label for="icon">Загрузите иконку</Label>
            <Input type="file" name="file" id="icon" />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            if (isNew) {
              addService({ description, title, iconPath });
            } else {
              saveService({ id, description, title, iconPath });
            }
            cleanUp();
          }}
        >
          {isNew ? 'Создать' : 'Сохранить'}
        </Button>{' '}
        <Button color="secondary" onClick={() => cleanUp()}>
          Отмена
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(
  state => ({
    formState: state.adminSettings.serviceModalReducer
  }),

  dipatch => ({
    onChangeField: (fieldName, value) => {
      dipatch({ type: 'FIELD_CHANGE', payload: { fieldName, value } });
    },
    cleanUp: () => {
      dipatch({ type: 'CLEAN_UP' });
    },
    addService: service => {
      dipatch({ type: 'ADD_SERVICE', payload: service });
    },
    saveService: service => {
      dipatch({ type: 'SAVE_SERVICE', payload: service });
    }
  })
)(ServicesEditForm);
