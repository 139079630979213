export const SET_REGIONS = 'SET_REGIONS';
export const CLEAR_REGIONS = 'CLEAR_REGIONS';
export const SET_SELECTED_REGIONS = 'SET_SELECTED_REGIONS';
export const RESET_SELECTED_REGIONS = 'RESET_SELECTED_REGIONS';

export const SET_EXPANDED_LIST = 'SET_EXPANDED_LIST';
export const CLEAR_EXPANDED_LIST = 'CLEAR_EXPANDED_LIST';

export const SET_REGION_LIST_FROM_BUDGET = 'SET_REGION_LIST_FROM_BUDGET';
export const CLEAR_REGION_LIST_FROM_BUDGET = 'CLEAR_REGION_LIST_FROM_BUDGET';
