import { combineReducers } from 'redux';
import { generateReducers } from 'src/helpers/reduxRequestHelper';
import GeoRegionReducer from 'src/components/GeoRegions/store/reducers';
import BudgetDiscountReducer from '../BudgetCalculationResult/store/reducers';
import {
  actionTypesRubrics,
  actionTypesKeywords,
  actionTypesDictionary,
  actionTypesCategories,
  actionTypesBudgetForecast,
  actionTypesBudgetForecastById,
  paginationActionTypes
} from './actionTypes';
import {
  actionTypesBudgetCustomForecastCreate,
  actionTypesBudgetCustomForecastById
} from '../BudgetCalculationResult/store/actionTypes';

const handleDictionaryData = data => {
  const { result } = data;
  return result;
};

const handleForecastsData = data => {
  return data;
};

const dictionaryReducer = generateReducers(
  actionTypesDictionary,
  handleDictionaryData
);
const rubricsReducer = generateReducers(actionTypesRubrics);
const keywordsReducer = generateReducers(actionTypesKeywords);
const categoriesReducer = generateReducers(actionTypesCategories);
const budgetForecastReducer = generateReducers(
  actionTypesBudgetForecast,
  handleForecastsData
);
const budgetForecastReducerById = generateReducers(
  actionTypesBudgetForecastById
);

const customBudgetForecastReducer = generateReducers(
  actionTypesBudgetCustomForecastCreate,
  handleForecastsData
);
const customBudgetForecastReducerById = generateReducers(
  actionTypesBudgetCustomForecastById
);

const initialStatePaginationReducer = {
  page: 1,
  pages: 1,
  perPage: 10,
  sort: 'id',
  order: 'desc',
  query: ''
};

export const paginationsReducer = (
  state = initialStatePaginationReducer,
  action
) => {
  const { page, pages, perPage, sort, order, type, query } = action;

  switch (type) {
    case paginationActionTypes.SET_PAGINATION_PAGE:
      return { ...state, page };
    case paginationActionTypes.SET_PAGINATION_PAGES:
      return { ...state, pages };
    case paginationActionTypes.SET_PER_PAGE:
      return { ...state, perPage };
    case paginationActionTypes.SET_PAGE_SORT_FIELD:
      return { ...state, sort };
    case paginationActionTypes.SET_PAGE_SORT_ORDER:
      return { ...state, order };
    case paginationActionTypes.SET_QUERY_PARAMS:
      return { ...state, query };
    default:
      return state;
  }
};

export default combineReducers({
  dictionary: dictionaryReducer,
  rubricsReducer: rubricsReducer,
  keywords: keywordsReducer,
  geoRegions: GeoRegionReducer,
  categories: categoriesReducer,
  budgetForecasts: budgetForecastReducer,
  budgetForecast: budgetForecastReducerById,
  budgetDiscount: BudgetDiscountReducer,
  customForecastById: customBudgetForecastReducerById,
  customForecast: customBudgetForecastReducer,
  paginationParams: paginationsReducer
});
