import React, { Component } from 'react';
import { Card, Col, Row, CardBody, CardHeader } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faEnvelope,
  faPhone,
  faUserTie,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import { ExtraMenu } from 'src/components';
import AdditionalEmail from './components/AdditionalEmail';
import AdditionalPhone from './components/AdditionalPhone';

const SignupSchema = Yup.object().shape({
  organisationName: Yup.string()
    .min(2, 'Название короткое!')
    .required('Обязательно к заполнению'),
  contactPhone: Yup.string()
    .length(17, 'Введите номер полностью')
    .required('Обязательно к заполнению'),
  contactEmail: Yup.string()
    .email('Введите корректный email')
    .required('Обязательно к заполнению'),
  inn: Yup.string()
    .length(10, 'Введите код полностью')
    .required('Обязательно к заполнению'),
  ogrn: Yup.string()
    .length(10, 'Введите код полностью')
    .required('Обязательно к заполнению'),
  kpp: Yup.string().min(10, 'Введите код полностью'),
  legalAdress: Yup.string()
    .min(10, 'Введите адрес полностью!')
    .required('Обязательно к заполнению'),
  fullName: Yup.string()
    .min(5, 'Введите полное имя организации!')
    .required('Обязательно к заполнению'),
  mailingAdress: Yup.string()
    .min(10, 'Введите адрес полностью!')
    .required('Обязательно к заполнению'),
  bik: Yup.string()
    .length(9, 'Введите номер счета полностью!')
    .required('Обязательно к заполнению'),
  checkingAccount: Yup.string()
    .length(20, 'Введите номер счета полностью!')
    .required('Обязательно к заполнению'),
  korr: Yup.string()
    .length(20, 'Введите номер счета полностью!')
    .required('Обязательно к заполнению'),
  bankName: Yup.string()
    .min(5, 'Введите полное имя организации!')
    .required('Обязательно к заполнению'),
  additionalEmail: Yup.string()
    .email('Введите корректный email')
    .required('Обязательно к заполнению'),
  additionalEmailTitle: Yup.string()
    .min(2, 'Слишком маленькое название')
    .required('Обязательно к заполнению')
});

export default class AddClient extends Component {
  maxId = 100;

  constructor() {
    super();
    this.state = {
      showPaymentData: false,
      likeLegalInput: false,
      disabled: false,
      legalInputVal: '',
      legalInputValue: '',
      additionalEmail: false,
      additionalPhone: false,
      emailData: null,
      phoneData: null
    };
  }

  onChangeHandler = e => {
    this.setState({ legalInputVal: e.target.value });
  };

  onBtnClickHandler = () => {
    this.setState(prevState => ({ likeLegalInput: !prevState.likeLegalInput }));
  };

  toggleDisabled = () => {
    this.setState(prevState => ({ disabled: !prevState.disabled }));
  };

  addAdditionnalEmail = () => {
    this.setState(prevState => ({
      additionalEmail: !prevState.additionalEmail
    }));
  };

  addAdditionnalPhone = () => {
    this.setState(prevState => ({
      additionalPhone: !prevState.additionalPhone
    }));
  };

  addEmail = values => {
    const { label, title } = values;
    const email = {
      label,
      title,
      id: this.maxId++
    };
    this.setState({ emailData: email });
    this.setState(prevState => ({
      additionalEmail: !prevState.additionalEmail
    }));
  };

  addPhone = values => {
    const { label, title } = values;
    const phone = {
      label,
      title,
      id: this.maxId++
    };
    this.setState({ phoneData: phone });
    this.setState(prevState => ({
      additionalPhone: !prevState.additionalPhone
    }));
  };

  onSubmit = () => {};

  addPaymentData() {
    this.setState(prevState => ({
      showPaymentData: !prevState.showPaymentData
    }));
  }

  render() {
    const {
      showPaymentData,
      likeLegalInput,
      disabled,
      emailData,
      additionalEmail,
      phoneData,
      additionalPhone,
      legalInputVal,
      legalInputValue
    } = this.state;
    const show = {
      display: 'block'
    };
    const hide = {
      display: 'none'
    };

    return (
      <div className="animated fadeIn">
        <Row>
          <ExtraMenu page="topThree" />
          <Col>
            <Formik
              initialValues={{
                organisationName: '',
                contactPhone: '',
                contactEmail: '',
                inn: '',
                ogrn: '',
                kpp: '',
                legalAdress: '',
                fullName: '',
                mailingAdress: '',
                bankName: '',
                additionalEmail: '',
                additionalEmailTitle: ''
              }}
              validationSchema={SignupSchema}
              onSubmit={this.onSubmit}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched
              }) => (
                <Form
                  className="new-client-form"
                  id="needs-validation"
                  onSubmit={handleSubmit}
                >
                  <Card>
                    <CardHeader>
                      <h4 className="form-section-title">Основные данные</h4>
                    </CardHeader>
                    <CardBody>
                      <div className="form-group col-5">
                        <label
                          htmlFor="organisationName"
                          className="label-name"
                        >
                          <FontAwesomeIcon icon={faUserTie} />
                          <span>Название организации</span>
                        </label>
                        <Field
                          name="organisationName"
                          type="text"
                          id="organisationName"
                          placeholder="Любимый клиент"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.organisationName}
                          className={
                            errors.organisationName && touched.organisationName
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.organisationName && touched.organisationName ? (
                          <div className="input-isRequired">
                            {errors.organisationName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="contactPhone" className="label-name">
                          <FontAwesomeIcon icon={faPhone} />
                          <span>Контактный телефон</span>
                        </label>
                        <MaskedInput
                          mask={[
                            '+',
                            '7',
                            '(',
                            /[9]/,
                            /\d/,
                            /\d/,
                            ')',
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/,
                            '-',
                            /\d/,
                            /\d/
                          ]}
                          guide={false}
                          id="contactPhone"
                          type="text"
                          name="contactPhone"
                          placeholder="+7(900)000-00-00"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contactPhone}
                          className={
                            errors.contactPhone && touched.contactPhone
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.contactPhone && touched.contactPhone ? (
                          <div className="input-isRequired">
                            {errors.contactPhone}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="contactEmail" className="label-name">
                          <FontAwesomeIcon icon={faEnvelope} />
                          <span>Email address</span>
                        </label>
                        <Field
                          name="contactEmail"
                          type="email"
                          id="contactEmail"
                          placeholder="mail@mail.ru"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.contactEmail}
                          className={
                            errors.contactEmail && touched.contactEmail
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.contactEmail && touched.contactEmail ? (
                          <div className="input-isRequired">
                            {errors.contactEmail}
                          </div>
                        ) : null}
                      </div>
                      {emailData && (
                        <div className="form-group col-5">
                          <label
                            htmlFor="additionalEmail"
                            className="label-name"
                          >
                            <FontAwesomeIcon icon={faEnvelope} />
                            <span>{emailData.title}</span>
                            <button
                              className="turn-edit-mode"
                              type="button"
                              onClick={() => this.toggleDisabled()}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              className="turn-edit-mode del"
                              type="button"
                              onClick={() => this.toggleDisabled()}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </label>
                          <Field
                            name="additionalEmail"
                            type="email"
                            id="additionalEmail"
                            placeholder="mail@mail.ru"
                            disabled={!disabled}
                            value={emailData.label}
                            className="form-control"
                          />
                        </div>
                      )}
                      {phoneData && (
                        <div className="form-group col-5">
                          <label
                            htmlFor="additionalPhone"
                            className="label-name"
                          >
                            <FontAwesomeIcon icon={faPhone} />
                            <span>{phoneData.title}</span>
                          </label>
                          <MaskedInput
                            mask={[
                              '+',
                              '7',
                              '(',
                              /[9]/,
                              /\d/,
                              /\d/,
                              ')',
                              ' ',
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/
                            ]}
                            guide={false}
                            id="additionalPhone"
                            type="text"
                            name="additionalPhone"
                            placeholder="+7(900)000-00-00"
                            value={phoneData.label}
                            className="form-control"
                          />
                        </div>
                      )}
                      <Row
                        style={additionalEmail ? show : null}
                        className="additional-row col-5 animated fadeIn"
                      >
                        <AdditionalEmail
                          addEmail={this.addEmail}
                          cancelEmail={this.addAdditionnalEmail}
                        />
                      </Row>
                      <div
                        className="col-5 top-three-page__btn mb-3"
                        style={additionalEmail ? hide : null}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary btn-lg animated fadeIn"
                          onClick={() => this.addAdditionnalEmail()}
                        >
                          Дополнительный email
                        </button>
                      </div>
                      <Row
                        style={additionalPhone ? show : null}
                        className="additional-row col-5 animated fadeIn"
                      >
                        <AdditionalPhone
                          addPhone={this.addPhone}
                          cancelPhone={this.addAdditionnalPhone}
                        />
                      </Row>
                      <div
                        className="col-5 top-three-page__btn"
                        style={additionalPhone ? hide : null}
                      >
                        <button
                          type="button"
                          className="btn btn-secondary btn-lg animated fadeIn"
                          onClick={() => this.addAdditionnalPhone()}
                        >
                          Дополнительный телефон
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h4 className="form-section-title">Данные организации</h4>
                    </CardHeader>
                    <CardBody className="d-flex flex-wrap">
                      <div className="form-group col-5">
                        <label htmlFor="inn" className="label-name">
                          ИНН
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/
                          ]}
                          guide={false}
                          id="inn"
                          type="text"
                          name="inn"
                          placeholder="12343435657"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.inn}
                          className={
                            errors.inn && touched.inn
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.inn && touched.inn ? (
                          <div className="input-isRequired">{errors.inn}</div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="ogrn" className="label-name">
                          ОГРН/ОГРНИП
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/
                          ]}
                          guide={false}
                          name="ogrn"
                          type="text"
                          id="ogrn"
                          placeholder="12343435657"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ogrn}
                          className={
                            errors.ogrn && touched.ogrn
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.ogrn && touched.ogrn ? (
                          <div className="input-isRequired">{errors.ogrn}</div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="kpp" className="label-name">
                          КПП
                        </label>
                        <Field
                          name="kpp"
                          type="text"
                          id="kpp"
                          placeholder="12343435657"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.kpp}
                          className={
                            errors.kpp && touched.kpp
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.kpp && touched.kpp ? (
                          <div className="input-isRequired">{errors.kpp}</div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="legalAdress" className="label-name">
                          Юридический адрес
                        </label>
                        <Field
                          name="legalAdress"
                          type="text"
                          id="legalAdress"
                          placeholder="129323, г.Москва, ул. Седова к.1, 268"
                          onBlur={handleBlur}
                          className={
                            errors.legalAdress && touched.legalAdress
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                          onChange={handleChange}
                          value={legalInputVal}
                          onInput={this.onChangeHandler}
                        />
                        {errors.legalAdress && touched.legalAdress ? (
                          <div className="input-isRequired">
                            {errors.legalAdress}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="fullName" className="label-name">
                          <span>Полное название организации</span>
                        </label>
                        <Field
                          name="fullName"
                          type="text"
                          id="fullName"
                          placeholder="ООО 'Ромашка'"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={legalInputValue}
                          className={
                            errors.fullName && touched.fullName
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.fullName && touched.fullName ? (
                          <div className="input-isRequired">
                            {errors.fullName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="mailingAdress" className="label-name">
                          Почтовый адрес
                        </label>
                        <Field
                          name="mailingAdress"
                          type="text"
                          id="mailingAdress"
                          placeholder="129323, г.Москва, ул. Седова к.1, 268"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={
                            likeLegalInput
                              ? legalInputVal
                              : values.mailingAdress
                          }
                          disabled={likeLegalInput}
                          className={
                            errors.mailingAdress && touched.mailingAdress
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.mailingAdress && touched.mailingAdress ? (
                          <fdiv className="input-isRequired">
                            {errors.mailingAdress}
                          </fdiv>
                        ) : null}
                      </div>
                      <div className="col-5" />
                      <div className="form-check coincides-with-legal col-4">
                        <label className="form-check-label">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onClick={this.onBtnClickHandler}
                          />
                          <span>Совпадает с юридическим</span>
                        </label>
                      </div>
                    </CardBody>
                  </Card>
                  <Card
                    className="payment-data"
                    style={showPaymentData ? show : hide}
                  >
                    <CardHeader className="form-section-header">
                      <h4 className="form-section-title">Платежные данные</h4>
                      <button
                        className="turn-edit-mode"
                        type="button"
                        onClick={() => this.toggleDisabled()}
                        style={showPaymentData ? hide : show}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </CardHeader>
                    <CardBody className="d-flex flex-wrap">
                      <div className="form-group col-5">
                        <label htmlFor="bik" className="label-name">
                          БИК
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/
                          ]}
                          guide={false}
                          name="bik"
                          type="text"
                          id="bik"
                          placeholder="5634348"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.bik}
                          // disabled={disabled}
                          className={
                            errors.bik && touched.bik
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.bik && touched.bik ? (
                          <div className="input-isRequired">{errors.bik}</div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="checkingAccount" className="label-name">
                          Расчетный счет
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/
                          ]}
                          guide={false}
                          name="checkingAccount"
                          type="text"
                          id="checkingAccount"
                          placeholder="4565845639574025739"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.checkingAccount}
                          // disabled={disabled}
                          className={
                            errors.checkingAccount && touched.checkingAccount
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.checkingAccount && touched.checkingAccount ? (
                          <div className="input-isRequired">
                            {errors.checkingAccount}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="korr" className="label-name">
                          Корр/счет
                        </label>
                        <MaskedInput
                          mask={[
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/
                          ]}
                          guide={false}
                          name="korr"
                          type="text"
                          id="korr"
                          placeholder="4565845639574025739"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.korr}
                          // disabled={disabled}
                          className={
                            errors.korr && touched.korr
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.korr && touched.korr ? (
                          <div className="input-isRequired">{errors.korr}</div>
                        ) : null}
                      </div>
                      <div className="form-group col-5">
                        <label htmlFor="bankName" className="label-name">
                          Название банка
                        </label>
                        <Field
                          name="bankName"
                          type="text"
                          id="bankName"
                          placeholder="АО Деньги всем"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.bankName}
                          // disabled={disabled}
                          className={
                            errors.bankName && touched.bankName
                              ? 'form-control invalid'
                              : 'form-control'
                          }
                        />
                        {errors.bankName && touched.bankName ? (
                          <div className="input-isRequired">
                            {errors.bankName}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-edit-mode col-12">
                        <Row>
                          <div
                            className="col-5"
                            style={showPaymentData ? show : hide}
                          >
                            <button
                              type="button"
                              className="btn btn-success btn-lg"
                            >
                              Сохранить
                            </button>
                          </div>
                          <div
                            className="col-5"
                            style={!showPaymentData ? show : hide}
                          >
                            <button
                              type="button"
                              className="btn btn-danger btn-lg"
                            >
                              Удалить
                            </button>
                          </div>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="add-client-btns">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg add-payment-data"
                          onClick={() => this.addPaymentData()}
                          style={showPaymentData ? show : null}
                        >
                          Добавить платежные реквизиты
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="add-client-btns">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg add-client-btn"
                        >
                          Добавить клиента
                        </button>
                      </div>
                    </CardBody>
                  </Card>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}
