import React, { useEffect } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const PaginationClass = props => {
  const { pages, page: currentPage, setPaginationPage } = props;
  if (pages === 1) {
    return null;
  }

  const changePaginationPage = page => {
    setPaginationPage(page);
  };

  const renderPaginationItem = page => {
    return (
      <PaginationItem active={currentPage === page} key={`page_${page}`}>
        <PaginationLink href="#" onClick={() => changePaginationPage(page)}>
          {page}
        </PaginationLink>
      </PaginationItem>
    );
  };
  const renderEmptyItem = (key = 'empt1') => {
    return (
      <PaginationItem key={`page_${key}`}>
        <PaginationLink href="#">...</PaginationLink>
      </PaginationItem>
    );
  };

  const renderPaginationPages = () => {
    const firstPart = (isLast, isFirst) => {
      if (isLast) {
        return renderPagesByPagesCount()
          .slice(0, 3)
          .map(page => {
            return renderPaginationItem(page);
          });
      }
      if (isFirst) {
        return renderPagesByPagesCount()
          .slice(0, 6)
          .map(page => {
            return renderPaginationItem(page);
          });
      }
      if (currentPage - 2 >= 2) {
        return renderPagesByPagesCount()
          .slice(0, 2)
          .map(page => {
            return renderPaginationItem(page);
          });
      } else {
        return null;
      }
    };
    const middlePart = renderPagesByPagesCount()
      .slice(currentPage - 1, currentPage + 2)
      .map(page => {
        return renderPaginationItem(page);
      });
    const emptyPart = key => {
      return renderEmptyItem(key);
    };
    const lastPart = isLast => {
      if (isLast) {
        return renderPagesByPagesCount()
          .slice(-5)
          .map(page => {
            return renderPaginationItem(page);
          });
      }
      return renderPagesByPagesCount()
        .slice(-2)
        .map(page => {
          return renderPaginationItem(page);
        });
    };
    if (renderPagesByPagesCount().length < 10)
      return renderPagesByPagesCount().map(page => {
        return renderPaginationItem(page);
      });
    if (
      currentPage + 2 >=
      renderPagesByPagesCount()[renderPagesByPagesCount().length - 3]
    )
      return [...firstPart(true), emptyPart(), ...lastPart(true)];
    if (currentPage <= 3)
      return [...firstPart(false, true), emptyPart(), ...lastPart(false)];
    return [
      ...firstPart(),
      emptyPart('empt1'),
      ...middlePart,
      emptyPart('empt2'),
      ...lastPart()
    ];
  };

  const renderPagesByPagesCount = () => {
    let newPagesArray = [],
      i = 0;
    do {
      i++;
      newPagesArray.push(i);
    } while (i < pages);
    return newPagesArray;
  };

  const renderPrevNextButton = (isPrevButton = true) => {
    let disabled = false;
    let onClick = () => {};

    if (isPrevButton && currentPage === 1) {
      disabled = true;
    }

    if (!isPrevButton && currentPage === pages) {
      disabled = true;
    }

    if (isPrevButton && currentPage !== 1) {
      onClick = () => changePaginationPage(currentPage - 1);
    }

    if (!isPrevButton && currentPage !== pages) {
      onClick = () => changePaginationPage(currentPage + 1);
    }

    return (
      <PaginationItem>
        <PaginationLink
          previous={isPrevButton}
          next={!isPrevButton}
          disabled={disabled}
          href="#"
          onClick={onClick}
        />
      </PaginationItem>
    );
  };

  const renderFirstLastButton = (isFirstButton = true) => {
    let disabled = false;
    let onClick = () => {};

    if (isFirstButton && currentPage === 1) {
      disabled = true;
    }

    if (!isFirstButton && currentPage === pages) {
      disabled = true;
    }

    if (isFirstButton && currentPage !== 1) {
      onClick = () => changePaginationPage(1);
    }

    if (!isFirstButton && currentPage !== pages) {
      onClick = () => changePaginationPage(pages);
    }

    return (
      <PaginationItem>
        <PaginationLink
          first={isFirstButton}
          last={!isFirstButton}
          disabled={disabled}
          href="#"
          onClick={onClick}
        />
      </PaginationItem>
    );
  };

  return (
    <Pagination>
      {renderFirstLastButton(true)}
      {renderPrevNextButton(true)}
      {renderPaginationPages()}
      {renderPrevNextButton(false)}
      {renderFirstLastButton(false)}
    </Pagination>
  );
};

export default PaginationClass;
