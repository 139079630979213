const topThreeItemsByRole = (role = 'admin') => {
  return [
    {
      name: 'Главная',
      url: '/dashboard/top-three',
      icon: 'icon-speech'
    },
    {
      name: 'Прогноз бюджета',
      url: '/dashboard/top-three/budget-forecast',
      icon: 'icon-speech'
    },
    {
      name: 'Создание компании',
      url: '/dashboard/top-three/create-company',
      icon: 'icon-speech'
    },
    {
      name: 'Мои клиенты',
      url: '/dashboard/top-three/#',
      icon: 'icon-speech'
    },
    {
      name: 'Подбор запросов',
      url: '/dashboard/top-three/#',
      icon: 'icon-speech'
    },
    {
      name: 'Полезные инструменты',
      url: '/dashboard/top-three/#',
      icon: 'icon-speech'
    },
    {
      name: 'Статистика',
      url: '/dashboard/top-three/#',
      icon: 'icon-speech'
    },
    {
      name: 'Документы',
      url: '/dashboard/top-three/#',
      icon: 'icon-speech'
    },
    role === 'admin'
      ? {
          name: 'Настройки',
          url: '/dashboard/top-three/settings',
          icon: 'icon-speech'
        }
      : null,
    {
      name: 'Справка',
      url: '/dashboard/top-three/#',
      icon: 'icon-speech'
    }
  ].filter(Boolean);
};

export const menuByRole = () => {
  const role = localStorage.role;
  return {
    topThree: {
      items: topThreeItemsByRole(role)
    }
  };
};

export default {
  topThree: {
    items: topThreeItemsByRole()
  }
};
