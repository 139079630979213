import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col
} from 'reactstrap';

import icons from './icons-mock';

const IconsModal = props => {
  const { buttonLabel, className, currentImage, handleChange } = props;

  useEffect(() => {
    chooseIcon(currentImage);
  }, [currentImage]);

  const [modal, setModal] = useState(false);
  const [backdrop, setBackdrop] = useState(true);
  const [activeIcon, chooseIcon] = useState('');

  const toggleAndSend = () => {
    setModal(false);
    if (activeIcon !== currentImage) {
      handleChange(activeIcon);
    }
  };

  const onClose = () => {
    setModal(!modal);
    chooseIcon(currentImage);
  };

  const icon = icons.map(icon => {
    return (
      <Col
        xs="6"
        sm="4"
        md="3"
        key={icon}
        className={`${activeIcon === icon ? 'primaryIcon' : ''} pointer`}
        onClick={() => chooseIcon(icon)}
      >
        <i className={`${icon} icons font-2xl d-block mt-4`} />
        {icon}
      </Col>
    );
  });

  return (
    <div>
      <Form inline onSubmit={e => e.preventDefault()}>
        <Button
          onClick={onClose}
          style={{ backgroundColor: '#E5EFFF', color: '#8CB8FF' }}
        >
          {buttonLabel}
        </Button>
      </Form>
      <Modal isOpen={modal} className={className} backdrop={backdrop}>
        <ModalHeader toggle={onClose}>{buttonLabel}</ModalHeader>
        <ModalBody>
          <Row className="text-center">{icon}</Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleAndSend}>
            Сохранить
          </Button>
          <Button color="secondary" onClick={onClose}>
            Отмена
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

IconsModal.propTypes = {
  className: PropTypes.string,
  buttonLabel: PropTypes.string,
  currentImage: PropTypes.string,
  handleChange: PropTypes.func
};

IconsModal.defaultProps = {
  className: '',
  buttonLabel: 'Выбрать иконку',
  currentImage: 'icon-speech',
  handleChange: () => {}
};

export default IconsModal;
