import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormInput } from 'src/components';
import resetPassword from './store/actions';

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Введите корректный Email')
    .required('Обязательно к заполнению')
});

const initialValues = {
  email: ''
};

const ResetPassword = props => {
  const { error, isLoading, handleResetPassword, data } = props,
    { message } = data;

  const submitResetPasswordParams = values => {
    const { email } = values;

    handleResetPassword(email);
  };

  const renderErrorMessage = () => {
    let errorString =
      'Не удалось сбросить пароль, проверьте правильность данных или попробуйте позже';

    return (
      <Col xs="12">
        <div className="alert alert-danger" role="alert">
          {errorString}
        </div>
      </Col>
    );
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Formik
                  validationSchema={ResetPasswordSchema}
                  initialValues={initialValues}
                  onSubmit={submitResetPasswordParams}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <h1>Восстановление пароля</h1>
                      <p className="text-muted">
                        Введите корректный E-mail, на него будет отправлено
                        письмо с инструкцией по восстановлению.
                      </p>

                      <FormInput
                        fieldKey="email"
                        placeholder="E-mail"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        values={values}
                        errors={errors}
                        touched={touched}
                        isLoading={isLoading}
                        type="text"
                        autoComplete="email"
                      />

                      {error && <Row>{renderErrorMessage()}</Row>}

                      {!error && data && message && (
                        <Row>
                          <Col xs="12">
                            <div className="alert alert-success" role="alert">
                              {message}
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col>
                          <Button
                            type="submit"
                            color="success"
                            block
                            className="px-4"
                            disabled={isLoading || !!errors.email}
                          >
                            Сбросить пароль
                          </Button>
                        </Col>
                        <Col xs="5" className="text-right">
                          <Link to="/login">
                            <Button type="button" color="link" className="px-0">
                              Войти
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth } = state,
    { ResetPasswordReducer } = auth,
    { request } = ResetPasswordReducer,
    { isLoading, errorMessage, data } = request;

  return {
    isLoading,
    error: errorMessage,
    data
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleResetPassword: data => {
      dispatch(resetPassword(data));
    }
  };
};

ResetPassword.propTypes = {
  handleResetPassword: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ResetPassword.defaultProps = {
  handleResetPassword: () => {},
  isLoading: false,
  error: '',
  data: {}
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
