import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress
} from 'reactstrap';

import { connect } from 'react-redux';
import { authLogout } from 'src/pages/Auth/Login/store/actions';
import user from 'src/assets/img/6.jpg';

const DefaultHeaderDropdown = ({ notif, accnt, tasks, mssgs, logoutUser }) => {
  const [dropdownOpen, toggle] = useState(false);

  const dropNotif = () => {
    const itemsCount = 5;
    return (
      <Dropdown
        nav
        className="d-md-down-none"
        isOpen={dropdownOpen}
        toggle={() => toggle(!dropdownOpen)}
      >
        <DropdownToggle nav>
          <i className="icon-bell" />
          <Badge pill color="danger">
            {itemsCount}
          </Badge>
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong>You have {itemsCount} notifications</strong>
          </DropdownItem>
          <DropdownItem>
            <i className="icon-user-follow text-success" /> New user registered
          </DropdownItem>
          <DropdownItem>
            <i className="icon-user-unfollow text-danger" /> User deleted
          </DropdownItem>
          <DropdownItem>
            <i className="icon-chart text-info" /> Sales report is ready
          </DropdownItem>
          <DropdownItem>
            <i className="icon-basket-loaded text-primary" /> New client
          </DropdownItem>
          <DropdownItem>
            <i className="icon-speedometer text-warning" /> Server overloaded
          </DropdownItem>
          <DropdownItem header tag="div" className="text-center">
            <strong>Server</strong>
          </DropdownItem>
          <DropdownItem>
            <div className="text-uppercase mb-1">
              <small>
                <b>CPU Usage</b>
              </small>
            </div>
            <Progress className="progress-xs" color="info" value="25" />
            <small className="text-muted">348 Processes. 1/4 Cores.</small>
          </DropdownItem>
          <DropdownItem>
            <div className="text-uppercase mb-1">
              <small>
                <b>Memory Usage</b>
              </small>
            </div>
            <Progress className="progress-xs" color="warning" value={70} />
            <small className="text-muted">11444GB/16384MB</small>
          </DropdownItem>
          <DropdownItem>
            <div className="text-uppercase mb-1">
              <small>
                <b>SSD 1 Usage</b>
              </small>
            </div>
            <Progress className="progress-xs" color="danger" value={90} />
            <small className="text-muted">243GB/256GB</small>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const logOutUser = () => {
    logoutUser();
  };

  const dropAccnt = () => (
    <Dropdown nav isOpen={dropdownOpen} toggle={() => toggle(!dropdownOpen)}>
      <DropdownToggle nav>
        <img
          src={user}
          className="img-avatar"
          alt="admin@bootstrapmaster.com"
        />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem header tag="div" className="text-center">
          <strong>Account</strong>
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-bell-o" /> Updates<Badge color="info">42</Badge>
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-envelope-o" /> Messages
          <Badge color="success">42</Badge>
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-tasks" /> Tasks<Badge color="danger">42</Badge>
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-comments" /> Comments
          <Badge color="warning">42</Badge>
        </DropdownItem>
        <DropdownItem header tag="div" className="text-center">
          <strong>Settings</strong>
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-user" /> Profile
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-wrench" /> Settings
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-usd" /> Payments
          <Badge color="secondary">42</Badge>
        </DropdownItem>
        <DropdownItem>
          <i className="fa fa-file" /> Projects
          <Badge color="primary">42</Badge>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem>
          <i className="fa fa-shield" /> Lock Account
        </DropdownItem>
        <DropdownItem onClick={logOutUser}>
          <i className="fa fa-lock" /> Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

  const dropTasks = () => {
    const itemsCount = 15;
    return (
      <Dropdown
        nav
        className="d-md-down-none"
        isOpen={dropdownOpen}
        toggle={() => toggle(!dropdownOpen)}
      >
        <DropdownToggle nav>
          <i className="icon-list" />
          <Badge pill color="warning">
            {itemsCount}
          </Badge>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem header tag="div" className="text-center">
            <strong>You have {itemsCount} pending tasks</strong>
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              Upgrade NPM &amp; Bower
              <span className="float-right">
                <strong>0%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="info" value={0} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              ReactJS Version
              <span className="float-right">
                <strong>25%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="danger" value={25} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              VueJS Version
              <span className="float-right">
                <strong>50%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="warning" value={50} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              Add new layouts
              <span className="float-right">
                <strong>75%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="info" value={75} />
          </DropdownItem>
          <DropdownItem>
            <div className="small mb-1">
              Angular 2 Cli Version
              <span className="float-right">
                <strong>100%</strong>
              </span>
            </div>
            <Progress className="progress-xs" color="success" value={100} />
          </DropdownItem>
          <DropdownItem className="text-center">
            <strong>View all tasks</strong>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const dropMssgs = () => {
    const itemsCount = 7;
    return (
      <Dropdown
        nav
        className="d-md-down-none"
        isOpen={dropdownOpen}
        toggle={() => toggle(!dropdownOpen)}
      >
        <DropdownToggle nav>
          <i className="icon-envelope-letter" />
          <Badge pill color="info">
            {itemsCount}
          </Badge>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem header tag="div">
            <strong>You have {itemsCount} messages</strong>
          </DropdownItem>
          <DropdownItem href="#">
            <div className="message">
              <div className="pt-3 mr-3 float-left">
                <div className="avatar">
                  <img
                    src={user}
                    className="img-avatar"
                    alt="admin@bootstrapmaster.com"
                  />
                  <span className="avatar-status badge-success" />
                </div>
              </div>
              <div>
                <small className="text-muted">John Doe</small>
                <small className="text-muted float-right mt-1">Just now</small>
              </div>
              <div className="text-truncate font-weight-bold">
                <span className="fa fa-exclamation text-danger" /> Important
                message
              </div>
              <div className="small text-muted text-truncate">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt...
              </div>
            </div>
          </DropdownItem>
          <DropdownItem href="#">
            <div className="message">
              <div className="pt-3 mr-3 float-left">
                <div className="avatar">
                  <img
                    src={user}
                    className="img-avatar"
                    alt="admin@bootstrapmaster.com"
                  />
                  <span className="avatar-status badge-warning" />
                </div>
              </div>
              <div>
                <small className="text-muted">Jane Doe</small>
                <small className="text-muted float-right mt-1">
                  5 minutes ago
                </small>
              </div>
              <div className="text-truncate font-weight-bold">
                Lorem ipsum dolor sit amet
              </div>
              <div className="small text-muted text-truncate">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt...
              </div>
            </div>
          </DropdownItem>
          <DropdownItem href="#">
            <div className="message">
              <div className="pt-3 mr-3 float-left">
                <div className="avatar">
                  <img
                    src={user}
                    className="img-avatar"
                    alt="admin@bootstrapmaster.com"
                  />
                  <span className="avatar-status badge-danger" />
                </div>
              </div>
              <div>
                <small className="text-muted">Janet Doe</small>
                <small className="text-muted float-right mt-1">1:52 PM</small>
              </div>
              <div className="text-truncate font-weight-bold">
                Lorem ipsum dolor sit amet
              </div>
              <div className="small text-muted text-truncate">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt...
              </div>
            </div>
          </DropdownItem>
          <DropdownItem href="#">
            <div className="message">
              <div className="pt-3 mr-3 float-left">
                <div className="avatar">
                  <img
                    src={user}
                    className="img-avatar"
                    alt="admin@bootstrapmaster.com"
                  />
                  <span className="avatar-status badge-info" />
                </div>
              </div>
              <div>
                <small className="text-muted">Joe Doe</small>
                <small className="text-muted float-right mt-1">4:03 AM</small>
              </div>
              <div className="text-truncate font-weight-bold">
                Lorem ipsum dolor sit amet
              </div>
              <div className="small text-muted text-truncate">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt...
              </div>
            </div>
          </DropdownItem>
          <DropdownItem href="#" className="text-center">
            <strong>View all messages</strong>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  return notif
    ? dropNotif()
    : accnt
    ? dropAccnt()
    : tasks
    ? dropTasks()
    : mssgs
    ? dropMssgs()
    : null;
};

const propTypes = {
  notif: PropTypes.bool,
  accnt: PropTypes.bool,
  tasks: PropTypes.bool,
  mssgs: PropTypes.bool
};

const defaultProps = {
  notif: false,
  accnt: false,
  tasks: false,
  mssgs: false
};

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(authLogout())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DefaultHeaderDropdown);
