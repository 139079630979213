import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import {
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarMinimizer,
  AppSidebarNav
} from '@coreui/react';
import routes from 'src/routes';
import { menuWithCustomIcon } from './_nav';
import DefaultHeader from './DefaultHeader';
import { isUserLogged } from '../../../helpers/routesHelper';
import { minimize, maximize, checkPath } from '../../../helpers/functions';

const DefaultLayout = props => {
  const {
      settings,
      location: { pathname }
    } = props,
    { img: budgetTopThreeIcon, name: budgetTopThreeName } = settings;

  useEffect(() => checkPath(pathname), [pathname]);

  const menuWithCustommIcon = useMemo(
    () =>
      menuWithCustomIcon({
        budgetTopThreeIcon,
        budgetTopThreeName
      }),
    [budgetTopThreeIcon, budgetTopThreeName]
  );

  const mainRoutes = useMemo(
    () =>
      routes.map(route => {
        return route.component ? (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={props => {
              document.title = `MallER Marketing - Личный кабинет ${route.name}`;
              return (
                <route.component {...props} />
              )
            }}
          />
        ) : null;
      }),
    []
  );
  return (
    <div className="app">
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarForm />
          <AppSidebarNav navConfig={menuWithCustommIcon} {...props} />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <Container fluid>
            <Switch>
              {!isUserLogged() && <Redirect to="/login" />}
              {mainRoutes}
              <Redirect exact from="/dashboard/*" to="/404" />
            </Switch>
          </Container>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { budget } = state,
    { settings } = budget,
    { data } = settings;

  return {
    settings: data
  };
};

export default withRouter(connect(mapStateToProps)(DefaultLayout));

//export default DefaultLayout;
