import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row, Button, CardColumns, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ExtraMenu } from 'src/components';
import { topThreeBtns } from 'src/constants/datas';

const TopThree = ({ settings }) => {
  const { name, description } = settings;

  const renderBtns = useMemo(
    () =>
      topThreeBtns.map(btn => (
        <Card body className="text-center" key={btn.name}>
          <Link to={btn.path}>
            <Button color="primary" size="lg" block>
              {btn.name}
            </Button>
          </Link>
        </Card>
      )),
    [name, description]
  );

  return (
    <div className="animated fadeIn">
      <Row>
        <ExtraMenu page="topThree" />
        <Col>
          <CardHeader>
            <h3>{name}</h3>
            <p>{description}</p>
          </CardHeader>
          <Card body className="text-center1 top-three-page">
            <CardColumns>{renderBtns}</CardColumns>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => {
  const { budget } = state,
    { settings } = budget,
    { data } = settings;

  return {
    settings: data
  };
};

export default connect(
  mapStateToProps,
  null
)(TopThree);
