import {
  SET_REGIONS,
  CLEAR_REGIONS,
  SET_SELECTED_REGIONS
} from './actionTypes';

export const initialState = {
  regions: [],
  selectedRegions: []
};

const GeoRegionsReducer = (state = initialState, action) => {
  const { type, regions, selectedRegions } = action;
  switch (type) {
    case SET_REGIONS:
      return { ...state, regions };
    case SET_SELECTED_REGIONS:
      return { ...state, selectedRegions };
    case CLEAR_REGIONS:
      return initialState;
    default:
      return state;
  }
};

export default GeoRegionsReducer;
