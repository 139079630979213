import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Row
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormInput } from 'src/components';
import loginActions from './store/actions';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Введите корректный Email')
    .required('Обязательно к заполнению'),
  password: Yup.string()
    .min(6, 'Пароль слишком короткий')
    .max(50, 'Пароль слишком длинный')
    .required('Обязательно к заполнению')
});

const Login = ({ error, isLoading, handleLogin }) => {
  const handleSubmit = values => {
    const { email, password } = values;
    handleLogin(email, password);
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="10">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Formik
                    validationSchema={LoginSchema}
                    initialValues={{ email: '', password: '' }}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <h1>Авторизация Mall'ER</h1>
                        <p className="text-muted">Войдите в свой аккаунт</p>
                        <FormInput
                          fieldKey="email"
                          placeholder="E-mail"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          values={values}
                          errors={errors}
                          touched={touched}
                          isLoading={isLoading}
                          type="text"
                          autoComplete="email"
                          inputIcon="icon-user"
                          addRowClass="mb-2"
                        />

                        <FormInput
                          fieldKey="password"
                          placeholder="Пароль"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          values={values}
                          errors={errors}
                          touched={touched}
                          isLoading={isLoading}
                          type="password"
                          autoComplete="password"
                          inputIcon="icon-lock"
                        />

                        {error && (
                          <Row>
                            <Col xs="12">
                              <div className="alert alert-danger" role="alert">
                                {error}
                              </div>
                            </Col>
                          </Row>
                        )}

                        <Row className="mt-3">
                          <Col xs="6">
                            <Button
                              type="submit"
                              color="primary"
                              className="px-4"
                              disabled={
                                isLoading || !!(errors.email || errors.password)
                              }
                            >
                              Вход
                            </Button>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Link to="/forgot-password">
                              <Button color="link" className="px-0">
                                Забыли пароль?
                              </Button>
                            </Link>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
              <Card
                className="text-white bg-primary py-5 d-md-down-none"
                style={{ width: 35 + '%' }}
              >
                <CardBody className="text-center">
                  <div>
                    <h2>Зарегистрироваться</h2>
                    <p style={{ fontSize: 17 + 'px' }}>
                      Пройдите быструю регистрацию и получите больше
                      возможностей сервисов Mall'ER с одним аккаунтом на всех
                      устройствах и платформах
                    </p>
                    <Link to="/register">
                      <Button
                        type="button"
                        color="primary"
                        className="mt-3 pl-5 pr-5"
                        active
                        style={{ fontSize: 17 + 'px' }}
                      >
                        Зарегистрироваться
                      </Button>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth } = state,
    { LoginReducer } = auth,
    { request } = LoginReducer,
    { isLoading, errorMessage } = request;

  return {
    isLoading,
    error: errorMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLogin: (email, password) =>
      dispatch(loginActions.login({ email, password }))
  };
};

Login.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

Login.defaultProps = {
  isLoading: false,
  error: ''
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
