import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import findIcon from 'src/assets/img/find-icon.svg';
import '../css/search-panel.scss';

const SearchPanel = props => {
  const { query, setPaginationQueryParam } = props;

  return (
    <div className="users-search-panel">
      <div className="users-search-icon">
        <img src={findIcon} alt="find" />
      </div>
      <Input placeholder="Поиск по e-mail или ID" />
    </div>
  );
};

export default SearchPanel;
