import { combineReducers } from 'redux';
import store from '../../../store';

const initialServiceModalState = {
  id: null,
  isOpen: false,
  isNew: false,
  title: '',
  description: '',
  iconPath: ''
};

const serviceModalReducer = (state = initialServiceModalState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'FIELD_CHANGE':
      return { ...state, [payload.fieldName]: payload.value };
    case 'SET_CREATED':
      return { ...initialServiceModalState, isOpen: true, isNew: true };
    case 'SET_EDITED':
      return { ...state, ...payload, isNew: false };
    case 'CLEAN_UP':
      return { ...initialServiceModalState };
    default:
      return state;
  }
};

const initialServiceListState = [
  {
    id: 1,
    title: 'test title',
    description: 'some test description',
    iconPath: '/path/test/img.png'
  }
];

const serviceListReducer = (state = initialServiceListState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'DELETE_SERVICE':
      const itemToRemoveIndex = state.findIndex(item => item.id === payload.id);
      state.splice(itemToRemoveIndex, 1);
      return [...state];
    case 'ADD_SERVICE':
      payload.id = 22;
      return [...state, payload];
    case 'SAVE_SERVICE':
      const itemToChangeIndex = state.findIndex(item => item.id === payload.id);
      state[itemToChangeIndex] = payload;
      return [...state];
  }
  return state;
};

export default combineReducers({ serviceModalReducer, serviceListReducer });
