import { combineReducers } from 'redux';
import { generateReducers } from 'src/helpers/reduxRequestHelper';
import GeoRegionNewReducer from 'src/components/SearchAndChooseRegions/store/reducers';
import BudgetDiscountReducer from '../Budget/BudgetCalculationResult/store/reducers';

import {
  actionTypesRubrics,
  actionTypesKeywords,
  actionTypesDictionary,
  actionTypesCategories,
  actionTypesBudgetForecast,
  actionTypesBudgetForecastById
} from '../Budget/store/actionTypes';

import {
  actionTypesBudgetCustomForecastCreate,
  actionTypesBudgetCustomForecastById,
  actionTypesBudgetSpecialCustomForecastById,
  actionTypesBudgetSpecialCustomForecastCreate
} from '../Budget/BudgetCalculationResult/store/actionTypes';
import settingsReducer from '../Settings/store/reducers';
import { paginationsReducer } from '../Budget/store/reducers';

const handleDictionaryData = data => {
  const { result } = data;
  return result;
};

const handleForecastsData = data => {
  return data;
};

const dictionaryReducer = generateReducers(
  actionTypesDictionary,
  handleDictionaryData
);
const rubricsReducer = generateReducers(actionTypesRubrics);
const keywordsReducer = generateReducers(actionTypesKeywords);
const categoriesReducer = generateReducers(actionTypesCategories);
const budgetForecastReducer = generateReducers(
  actionTypesBudgetForecast,
  handleForecastsData
);
const budgetForecastReducerById = generateReducers(
  actionTypesBudgetForecastById
);

const customBudgetForecastReducer = generateReducers(
  actionTypesBudgetCustomForecastCreate,
  handleForecastsData
);
const customBudgetForecastReducerById = generateReducers(
  actionTypesBudgetCustomForecastById
);

const customSpecialBudgetForecastReducer = generateReducers(
  actionTypesBudgetSpecialCustomForecastCreate,
  handleForecastsData
);
const customSpecialBudgetForecastReducerById = generateReducers(
  actionTypesBudgetSpecialCustomForecastById
);

export default combineReducers({
  dictionary: dictionaryReducer,
  rubricsReducer: rubricsReducer,
  keywords: keywordsReducer,
  geoRegions: GeoRegionNewReducer,
  categories: categoriesReducer,
  budgetForecasts: budgetForecastReducer,
  budgetForecast: budgetForecastReducerById,
  budgetDiscount: BudgetDiscountReducer,
  customForecastById: customBudgetForecastReducerById,
  customForecast: customBudgetForecastReducer,
  customSpecialForecastById: customSpecialBudgetForecastReducerById,
  customSpecialForecast: customSpecialBudgetForecastReducer,
  settings: settingsReducer,
  paginationSettings: paginationsReducer
});
