import { combineReducers } from 'redux';

import auth from 'src/pages/Auth/reducers';
import topThreeReducer from 'src/pages/TopThree/store/reducers';
import serviceReducers from 'src/pages/Users/store/reducers';

export default combineReducers({
  auth,
  budget: topThreeReducer,
  adminSettings: serviceReducers
});
