import React from 'react';
import { connect } from 'react-redux';
import { FaTrash, FaEdit, FaPlus } from 'react-icons/fa';
import { Table, Card, CardHeader, CardBody, Button } from 'reactstrap';
import ServicesEditForm from './servicesEditForm';

const alignCenter = {
  display: 'grid',
  gridTemplateColumns: 'max-content max-content',
  gridGap: '10px'
};

const pointer = {
  cursor: 'pointer'
};

const UsersSettings = props => {
  const { setCreated, setEdited, servicesList, deleteService } = props;
  const renderRow = (id, title, path, description) => {
    return (
      <tr>
        <td>{id}</td>
        <td>{title}</td>
        <td>{path}</td>
        <td>
          <div style={alignCenter}>
            <Button close aria-label="Cancel">
              <span aria-hidden>
                <FaTrash
                  onClick={() =>
                    deleteService({
                      id,
                      title,
                      path,
                      description
                    })
                  }
                />
              </span>
            </Button>
            <Button close aria-label="Cancel">
              <span aria-hidden>
                <FaEdit
                  onClick={() =>
                    setEdited({ id, title, path, description, isOpen: true })
                  }
                />
              </span>
            </Button>
          </div>
        </td>
      </tr>
    );
  };
  const renderRows = () => {
    return servicesList.map(serviceItem =>
      renderRow(
        serviceItem.id,
        serviceItem.title,
        serviceItem.iconPath,
        serviceItem.description
      )
    );
  };
  return (
    <div className="usersSettings">
      <Card>
        <CardHeader className="budget-forecast__header">
          <h3 className="budget-forecast__title">Сервисы</h3>
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Путь иконки</th>
                <th align="center">Действия</th>
                <th>
                  <FaPlus
                    style={pointer}
                    onClick={() => {
                      setCreated();
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>{renderRows()}</tbody>
          </Table>
        </CardBody>
      </Card>
      <ServicesEditForm />
    </div>
  );
};

export default connect(
  state => ({
    servicesList: state.adminSettings.serviceListReducer
  }),

  dipatch => ({
    setCreated: () => {
      dipatch({ type: 'SET_CREATED' });
    },
    setEdited: service => {
      dipatch({ type: 'SET_EDITED', payload: service });
    },
    deleteService: service => {
      dipatch({ type: 'DELETE_SERVICE', payload: service });
    }
  })
)(UsersSettings);
