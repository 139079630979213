import {
  SET_DISCOUNT,
  SET_SELECTED_ALL_IDS,
  CLEAR_SELECTED_ALL_IDS,
  SET_SELECTED_ID,
  CLEAR_SELECTED_ID,
  CLEAR_BUDGET_DISCOUNT,
  CLEAR_BUDGET_TABLE,
  SET_BUDGET_TABLE,
  SET_TABLE_PRICES,
  CLEAR_TABLE_PRICES,
  SET_CHANGED_ID,
  CLEAR_CHANGED_ID,
  SET_CHANGED_ID_WITH_PHRASE,
  CLEAR_CHANGED_ID_WITH_PHRASE,
  SET_CUSTOM_FORECAST_ID,
  CLEAR_CUSTOM_FORECAST_ID,
  SET_CUSTOM_FORECAST_RECEIVED,
  CLEAR_CUSTOM_FORECAST_RECEIVED,
  CLEAR_ALL_CHANGED_IDS,
  SET_SPECIAL_CUSTOM_FORECAST_ID,
  CLEAR_SPECIAL_CUSTOM_FORECAST_ID,
  SET_SPECIAL_CUSTOM_FORECAST_RECEIVED,
  CLEAR_SPECIAL_CUSTOM_FORECAST_RECEIVED,
  SET_REMOVED_ROWS,
  CLEAR_REMOVED_ROWS
} from './actionTypes';

export const initialState = {
  discount: 0,
  selectedTableIds: [],
  changedTableIds: [],
  table: [],
  totalDirectPrice: 0,
  totalDirectTopThree: 0,
  totalDirectAfterThreeMonth: 0,
  changedTablePhrases: [],
  customForecastId: null,
  customForecastReceived: false,
  specialCustomForecastId: null,
  specialCustomForecastReceived: false,
  hasRemovedRows: false,
  clearPrice: 0,
  clearTopThree: 0
};

const sortArray = array => {
  return [...new Set(array)].sort();
};

const setChangeIdWithPhrase = (array = [], id, phrase, isValid) => {
  let newArray = [...array];
  let ids = sortArray(newArray.map(value => value.id));

  if (!ids.includes(id)) {
    newArray.push({ id, phrase, isValid });
  } else {
    const indexElem = newArray.findIndex(elem => elem.id === id);
    if (indexElem !== -1) {
      newArray[indexElem].phrase = phrase;
      newArray[indexElem].isValid = isValid;
    }
  }
  return newArray;
};

const clearChangeIdWithPhrase = (array = [], id) => {
  return array.filter(object => object.id !== id);
};

const BudgetDiscountReducer = (state = initialState, action) => {
  const {
    type,
    discount,
    ids,
    id,
    table,
    totalDirectPrice,
    totalDirectTopThree,
    totalDirectAfterThreeMonth,
    phrase,
    clearPrice,
    clearTopThree,
    isValid
  } = action;
  switch (type) {
    case SET_DISCOUNT:
      return { ...state, discount };
    case SET_SELECTED_ALL_IDS:
      return { ...state, selectedTableIds: ids };
    case CLEAR_SELECTED_ALL_IDS:
      return { ...state, selectedTableIds: [] };
    case SET_SELECTED_ID:
      return {
        ...state,
        selectedTableIds: sortArray([...state.selectedTableIds, id])
      };
    case CLEAR_SELECTED_ID:
      return {
        ...state,
        selectedTableIds: sortArray([
          ...state.selectedTableIds.filter(selId => selId !== id)
        ])
      };
    case SET_BUDGET_TABLE:
      return {
        ...state,
        table
      };
    case CLEAR_BUDGET_TABLE:
      return {
        ...state,
        table: []
      };
    case SET_TABLE_PRICES:
      return {
        ...state,
        totalDirectPrice,
        totalDirectTopThree,
        totalDirectAfterThreeMonth,
        clearPrice,
        clearTopThree
      };
    case CLEAR_TABLE_PRICES:
      return {
        ...state,
        totalDirectPrice: 0,
        totalDirectTopThree: 0,
        totalDirectAfterThreeMonth: 0,
        clearPrice: 0,
        clearTopThree: 0
      };
    case SET_CHANGED_ID:
      return {
        ...state,
        changedTableIds: sortArray([...state.changedTableIds, id])
      };
    case CLEAR_CHANGED_ID:
      return {
        ...state,
        changedTableIds: sortArray([
          ...state.changedTableIds.filter(selId => selId !== id)
        ])
      };
    case SET_CHANGED_ID_WITH_PHRASE:
      return {
        ...state,
        changedTablePhrases: setChangeIdWithPhrase(
          state.changedTablePhrases,
          id,
          phrase,
          isValid
        )
      };
    case CLEAR_CHANGED_ID_WITH_PHRASE:
      return {
        ...state,
        changedTablePhrases: clearChangeIdWithPhrase(
          state.changedTablePhrases,
          id
        )
      };
    case SET_CUSTOM_FORECAST_ID:
      return {
        ...state,
        customForecastId: id
      };
    case CLEAR_CUSTOM_FORECAST_ID:
      return {
        ...state,
        customForecastId: null
      };
    case SET_CUSTOM_FORECAST_RECEIVED:
      return {
        ...state,
        customForecastReceived: true
      };
    case CLEAR_CUSTOM_FORECAST_RECEIVED:
      return {
        ...state,
        customForecastReceived: false
      };
    case CLEAR_ALL_CHANGED_IDS:
      return {
        ...state,
        changedTableIds: [],
        changedTablePhrases: []
      };
    case CLEAR_BUDGET_DISCOUNT:
      return initialState;
    case SET_SPECIAL_CUSTOM_FORECAST_ID:
      return {
        ...state,
        specialCustomForecastId: id
      };
    case CLEAR_SPECIAL_CUSTOM_FORECAST_ID:
      return {
        ...state,
        specialCustomForecastId: null
      };
    case SET_SPECIAL_CUSTOM_FORECAST_RECEIVED:
      return {
        ...state,
        specialCustomForecastReceived: true
      };
    case CLEAR_SPECIAL_CUSTOM_FORECAST_RECEIVED:
      return {
        ...state,
        specialCustomForecastReceived: false
      };
    case SET_REMOVED_ROWS: {
      return { ...state, hasRemovedRows: true };
    }
    case CLEAR_REMOVED_ROWS: {
      return { ...state, hasRemovedRows: false };
    }
    default:
      return state;
  }
};

export default BudgetDiscountReducer;
