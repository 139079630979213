import {
  generateActions,
  generateActionTypes
} from 'src/helpers/reduxRequestHelper';
import { getAuthRole } from 'src/helpers/authToken';
import settingsActionTypes from './actionTypes';

const settingsAction = generateActions(
  settingsActionTypes,
  'budget_forecast_settings'
);

const customSettingsAction = generateActions(
  generateActionTypes('custom_settings'),
  'budget_forecast_settings'
);

const defaultSettings = {
  first_place_special: {
    name: '1 м. сср',
    key: 'first_place_special',
    ctr: 1,
    clicks: 1,
    index: 0
  },
  first_place_garant: {
    name: '1 м. в г. п.',
    key: 'first_place_garant',
    ctr: 1,
    clicks: 1,
    index: 1
  },
  fourth_place_garant: {
    name: '4 м. в г. п.',
    key: 'fourth_place_garant',
    ctr: 1,
    clicks: 1,
    index: 2
  }
};

const formDefaultSettings = {
  name: 'Топ 3 в поиске',
  img: 'icon-speech',
  description: '',
  settings_report: 'Топ 3 в поиске',
  percent_discount: 30,
  extra_charge_discount: 30,
  percent_discount_for_tree_month: 50,
  min_sum: 500,
  when_value_null: 300,
  min_random: 100,
  max_random: 300,
  type_report: 'first_place_special',
  settings_ctr_and_clicks: defaultSettings,
  when_value_null_top_three: 300,
  min_sum_top_three: 500,
  min_random_top_three: 0,
  max_random_top_three: 0
};

export const getSettings = () => {
  return (dispatch, getState) => {
    settingsAction.get(dispatch).then(() => {
      const state = getState(),
        { budget } = state,
        { settings } = budget,
        { data: dataSettings } = settings,
        { settings_ctr_and_clicks } = dataSettings;

      const ctrSettingsCorrect =
        settings_ctr_and_clicks &&
        Object.keys(settings_ctr_and_clicks).includes('first_place_special');

      if (!ctrSettingsCorrect) {
        const role = getAuthRole();
        const adminRole = role === 'admin';
        if (adminRole && !ctrSettingsCorrect) {
          dispatch(updateSettings(formDefaultSettings));
        }
      }
    });
  };
};

export const updateSettings = data => {
  const { settings_ctr_and_clicks, ...updateData } = data;
  const newData = {
    ...updateData,
    settings_ctr_and_clicks: JSON.stringify(settings_ctr_and_clicks)
  };

  return dispatch => {
    customSettingsAction.put(dispatch, newData).then(() => {
      settingsAction.get(dispatch);
    });
  };
};

export const clearSettings = () => {
  return dispatch => {
    dispatch(settingsAction.clear());
  };
};
