import React, { useEffect } from 'react';
import { Route, Switch, Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './App.scss';
import { DefaultLayout } from './components';
import {
  Login,
  Page404,
  Page500,
  Register,
  ResetPassword,
  ChangePassword
} from './pages';
import profileActions from './pages/Auth/Profile/store/actions';
import { authTokenSync, getAuthToken } from './helpers/authToken';
import { checkLoggedPath } from './helpers/routesHelper';
import history from './utils/history';
import { getSettings } from './pages/TopThree/Settings/store/actions';

const App = props => {
  useEffect(() => {
    const { getUserProfile, authTokenSync, getSettings } = props;
    authTokenSync();

    const token = getAuthToken();

    if (token) {
      getUserProfile();
      checkLoggedPath();
      getSettings();
    }
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" name="Login Page" render={() => <Login />} />
        <Route
          path="/register"
          name="Register Page"
          render={() => <Register />}
        />
        <Route
          path="/forgot-password"
          name="Reset Password Page"
          render={() => <ResetPassword />}
        />
        <Route
          path="/change-password"
          name="Change Password Page"
          render={props => <ChangePassword {...props} />}
        />
        <Route path="/404" name="Page 404" render={() => <Page404 />} />
        <Route path="/500" name="Page 500" render={() => <Page500 />} />
        <Route
          path="/dashboard"
          name="Home"
          render={props => <DefaultLayout {...props} />}
        />
        <Redirect exact from="/" to="/dashboard" />
        <Route render={() => <Page404 />} />
      </Switch>
    </Router>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProfile: () => dispatch(profileActions.getProfileInfo()),
    authTokenSync: () => dispatch(authTokenSync()),
    getSettings: () => dispatch(getSettings())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App);
